@import "@myclimate/styles/scss/10_colors.scss";
@import "@myclimate/styles/scss/bootstrap_customizations.scss";
@import "bootstrap-scss/bootstrap";
@import "@myclimate/styles/scss/myclimate.scss";

.modal-90w {
  max-width: 100%;
  margin: 0;
  top: 5vh;
  bottom: 0;
  left: 5vw;
  right: 0;
  height: 90vh;
  width: 90vw;
  display: flex;
}

// Required for tabs to have a pointer
a.nav-link {
  cursor: pointer;
}

a.link-collapse:hover {
  text-decoration: none;
}

.info-box {
  font-size: 1rem;
  line-height: inherit;
}

.icn-anmelden::before {
  content: "\e803";
}

.mainline {
  position: fixed;
}

.selector-box {
  border: 0.1rem solid $primary;
  max-width: 30%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-left: auto;

  @media (max-width: 768px) {
    max-width: 80%;
  }
}

.selector-input {
  margin-left: 1rem;
}


.transport-dropdown-container {
  margin-bottom: 10px;
  /* Add margin at the bottom for space */
}

.additional-input-container {
  margin-top: 10px;
  /* Add margin at the top for space */
}

.options-selection {
  width: 60%;
}